html,
body {
    margin: 0px;
    min-height: 100%;
    height: 100%;
}

* {
    box-sizing: border-box;
}

.content {
    line-height: 1.5;
    flex-grow: 1;

    h2 {
        font-size: 30px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.container {
    height: 100%;
    flex-direction: column;
    display: flex;
}

.layout-body {
    flex: 1;
    min-height: 0;
}

.side-nav-outer-toolbar .dx-drawer {
    height: calc(100% - 56px);
}

.padding-content {
    margin: 5px;
}

.content-block {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 20px;

    .screen-x-small & {
        margin-left: 20px;
        margin-right: 20px;
    }
}

.responsive-paddings {
    padding: 20px;

    .screen-large & {
        padding: 40px;
    }
}

.dx-card.wide-card {
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
    border-right: 0;
    border-left: 0;
}

.with-footer
    > .dx-scrollable-wrapper
    > .dx-scrollable-container
    > .dx-scrollable-content {
    height: 100%;

    & > .dx-scrollview-content {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }
}

$side-panel-min-width: 60px;
